import React from "react"
import { FooterWithCTA, SEO, Layout } from "@components"
import { Hero } from "@components"
import Content from "@components/pages/tools/Content.js"

const ToolsPage = () => {
  return (
    <>
      <Layout>
        <SEO
          title={"39 Top DynamoDB Tools [Management, ORMS, Mappers etc]"}
          description={
            "Whether its video training courses, podcasts or YouTube series - this post covers top resources that will help you learn DynamoDB."
          }
          canonical="https://dynobase.dev/dynamodb-tools/"
        />
        <Hero title={"39 Top DynamoDB Tools"} />
        <Content />
        <FooterWithCTA />
      </Layout>
    </>
  )
}

export default ToolsPage
