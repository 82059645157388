import React from "react"
import { Container } from "@components"
import { Box } from "rebass"
import data from "./Data"
import TutorialList from "./TutorialList"
import WithoutImageList from "./WithoutImageList"
import Headline from "@components/aggregation/Headline.js"
import Filter from "./Filter"

const Content = () => (
  <Container>
    <Box mt={[32, 62]}>
      <Filter />
      <Headline headline={"Management"} id="management" />
      <TutorialList data={data.management} noJustify />

      <Headline headline={"Infrastructure as Code"} id="iac" />
      <TutorialList data={data.iac} />

      <Headline headline={"Utility"} id="utility" />
      <TutorialList data={data.utility} />

      <Headline headline={"Local"} id="local" />
      <WithoutImageList data={data.local} />

      <Headline headline={"Backup & Restore"} id="backup" />
      <WithoutImageList data={data.backup} />

      <Headline headline={"Libraries: JavaScript"} id="libraries" />
      <WithoutImageList data={data.libraries.javascript} />

      <Headline headline={"Libraries: TypeScript"} id="libraries-typescript" />
      <WithoutImageList data={data.libraries.typescript} />

      <Headline headline={"Libraries: Python"} id="libraries-python" />
      <WithoutImageList data={data.libraries.python} />

      <Headline headline={"Libraries: Golang"} id="libraries-golang" />
      <WithoutImageList data={data.libraries.golang} />

      <Headline headline={"Libraries: Ruby"} id="libraries-ruby" />
      <WithoutImageList data={data.libraries.ruby} />

      <Headline headline={"Libraries: C#"} id="libraries-dotnet" />
      <WithoutImageList data={data.libraries.dotnet} />
    </Box>
  </Container>
)

export default Content
