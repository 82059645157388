import React from 'react';
import { Flex } from 'rebass';

const TutorialsPageFilter = () => (
    <Flex justifyContent={['space-evenly', 'space-between']} flexWrap='wrap'>
        <a href='#management' style={{textDecoration: 'none'}}>
            <p>Management</p>
        </a> / 
        <a href='#iac' style={{textDecoration: 'none'}}>
        <p>IaC &nbsp;</p> 
        </a> / 

        <a href='#utility' style={{textDecoration: 'none'}}>
        <p>Utility &nbsp;</p>
        </a> /

        <a href='#local' style={{textDecoration: 'none'}}>
        <p>Local &nbsp;</p>
        </a> /

        <a href='#backup' style={{textDecoration: 'none'}}>
        <p>Backup &nbsp;</p>
        </a> /

        <a href='#libraries' style={{textDecoration: 'none'}}>
        <p>Libraries &nbsp;</p>
        </a> 
    </Flex>      
);

export default TutorialsPageFilter;