import React from "react"
import { Flex, Image } from "rebass"
import { ExternalLink } from "@fragments"

const TutorialList = ({ data, noJustify }) => (
  <Flex flexWrap="wrap" justifyContent={noJustify ? "auto" : "space-between"}>
    {data.map((tutorial, index) => {
      return (
        <Flex
          key={index}
          width={[0.45, 0.31]}
          ml={noJustify && index === data.length - 1 ? 30 : 0}
        >
          <Flex flexDirection="column">
            {tutorial.image && (
              <ExternalLink to={tutorial.link} noStyle>
                <Image
                  src={`/images/tools/${tutorial.image}.png`}
                  className="shadow-hover"
                  style={{
                    borderRadius: 12,
                    overflow: "hidden",
                    boxShadow: "rgba(0, 0, 0, 0.15) 3px 4px 10px 4px",
                  }}
                />
              </ExternalLink>
            )}
            <ExternalLink href={tutorial.link} noStyle>
              <h4 style={{ lineHeight: "1.5", marginTop: "6px" }}>
                {tutorial.title}
              </h4>
            </ExternalLink>
          </Flex>
        </Flex>
      )
    })}
  </Flex>
)

export default TutorialList
