import React from "react"
import { Flex } from "rebass"

const TutorialList = ({ data }) => (
  <Flex flexDirection="column">
    {data.map((tutorial, index) => {
      return (
        <Flex key={index}>
          <Flex>
            <a
              href={tutorial.link}
              style={{ color: "inherit", textDecoration: "", marginTop: "4px" }}
            >
              <h4 style={{ lineHeight: "1.5", marginBottom: "4px" }}>
                {tutorial.title}
              </h4>
            </a>
            {tutorial.count && (
              <span
                style={{ marginTop: "0.4em", marginLeft: 5, fontSize: "0.8em" }}
              >
                ⭐️ {tutorial.count}
              </span>
            )}
            {tutorial.description && (
              <p style={{ alignSelf: "flex-end", marginLeft: "12px" }}>
                - &nbsp;{tutorial.description}
              </p>
            )}
          </Flex>
        </Flex>
      )
    })}
  </Flex>
)

export default TutorialList
