export default {
  management: [
    {
      title: "Dynobase",
      image: "dynobasetool",
      link: "https://dynobase.dev/",
    },
    {
      title: "NoSQL Workbench",
      image: "nosql-workbench",
      link:
        "https://docs.aws.amazon.com/amazondynamodb/latest/developerguide/workbench.html",
    },
  ],
  iac: [
    {
      title: "Serverless Framework",
      image: "serverless-framework",
      link: "https://www.serverless.com/",
    },
    {
      title: "Terraform",
      image: "terraform",
      link: "https://www.terraform.io/",
    },
    {
      title: "AWS CloudFormation",
      image: "aws-cloudformation",
      link: "https://aws.amazon.com/cloudformation/",
    },
    {
      title: "AWS Amplify",
      image: "aws-amplify",
      link: "https://aws.amazon.com/amplify/",
    },
    {
      title: "Pulumi",
      image: "pulumi",
      link: "https://www.pulumi.com/",
    },
    {
      title: "Begin",
      image: "begin",
      link: "https://begin.com/",
    },
    {
      title: "AWS CDK",
      image: "aws-cdk",
      link: "https://aws.amazon.com/cdk/",
    },
    {
      title: "AWS SAM",
      image: "aws-sam",
      link: "https://aws.amazon.com/serverless/sam/",
    },
  ],
  utility: [
    {
      title: "DynamoDB Pricing Calculator",
      image: "dynamodb-pricing-calculator",
      link: "https://dynobase.dev/dynamodb-pricing-calculator/",
    },
    {
      title: "DynamoDB Capacity Calculator",
      image: "dynamodb-item-size-calculator",
      link: "https://dynobase.dev/dynamodb-capacity-and-item-size-calculator/",
    },
    {
      title: "DynamoDB Query Builder",
      image: "dynamodb-visual-query-builder",
      link: "https://dynobase.dev/dynamodb-query-builder/",
    },
  ],
  local: [
    {
      title: "LocalStack DynamoDB",
      count: "22.9k",
      link: "https://github.com/localstack/localstack/",
      description:
        "A fully functional local AWS cloud stack - supports DynamoDB.",
    },
    {
      title: "Dynalite",
      count: 748,
      link: "https://github.com/mhart/dynalite",
      description: "An implementation of Amazon's DynamoDB built on LevelDB.",
    },
    {
      title: "Serverless DynamoDB",
      count: 469,
      link: "https://github.com/99xt/serverless-dynamodb-local",
      description:
        "Serverless Dynamodb Local Plugin - Allows to run dynamodb locally for serverless.",
    },
    {
      title: "Docker DynamoDB",
      count: 219,
      link: "https://github.com/dwmkerr/docker-dynamodb",
      description: "It's DynamoDB - in Docker!",
    },
    {
      title: "jest-dynamodb",
      count: 81,
      link: "https://github.com/shelfio/jest-dynamodb",
      description: "Jest preset for DynamoDB local server",
    },
  ],
  backup: [
    {
      title: "DynamoDB Dump",
      count: 584,
      link: "https://github.com/bchew/dynamodump",
      description: "Simple backup and restore for Amazon DynamoDB using Boto.",
    },
    {
      title: "DynamoDB Continuous Backups",
      count: 172,
      link: "https://github.com/awslabs/dynamodb-continuous-backup",
      description: "Continuous backup automation for Amazon DynamoDB.",
    },
    {
      title: "DynamoDB Backup to S3",
      count: 121,
      link: "https://github.com/markitx/dynamo-backup-to-s3",
      description: "Stream DynamoDB backups to S3",
    },
    {
      title: "Serverless DynamoDB Backups",
      count: 44,
      link: "https://github.com/alexdebrie/serverless-dynamodb-backups",
      description: "Automate your DynamoDB backups",
    },
    {
      title: "dynamodb-stream-elasticsearch",
      count: 84,
      link: "https://github.com/matrus2/dynamodb-stream-elasticsearch",
      description:
        "Missing blueprint for AWS Lambda. Reads stream from AWS DynamoDB and writes it to ElasticSearch",
    },
    {
      title: "copy-dynamodb-table",
      count: 79,
      link: "https://github.com/enGMzizo/copy-dynamodb-table",
      description:
        "Copy Dynamodb table to another in the same or different zone",
    },
  ],
  libraries: {
    javascript: [
      {
        title: "Dynamoose",
        link: "https://github.com/dynamoosejs/dynamoose/",
        description: "The most popular modeling tool, inspired by Mongoose.",
        count: "1.2k",
      },
      {
        title: "serverless-dynamodb-local",
        link: "https://github.com/99x/serverless-dynamodb-local",
        description:
          "Serverless Dynamodb Local Plugin - Allows to run dynamodb locally for serverless",
        count: 535,
      },
      {
        title: "Dynogels",
        link: "https://github.com/clarkie/dynogels",
        description:
          "Data mapper for DynamoDB, supports streaming and parallel scans.",
        count: 453,
      },
      {
        title: "Dynamite",
        count: 196,
        link: "https://github.com/Medium/dynamite",
        description: "Promise-based DynamoDB client from Medium.com team.",
      },
      {
        title: "Dynasty",
        count: 152,
        link: "https://github.com/victorquinn/dynasty",
        description: "Written in Coffeescript.",
      },
      {
        title: "AWS Pilot",
        count: 102,
        link: "https://github.com/awspilot/dynamodb-oop",
        description:
          "Speak fluent DynamoDB, write code with fashion, I Promise().",
      },
      {
        title: "DynamoDB Streams Processor",
        count: 40,
        link: "https://github.com/jeremydaly/dynamodb-streams-processor",
        description: "Simple tool to help process DynamoDB Streams.",
      },
      {
        title: "flynamo",
        count: 8,
        link: "https://github.com/flybondi/flynamo",
        description: "DynamoDB functional wrapper.",
      },
      {
        title: "ElectroDB",
        count: 8,
        link: "https://github.com/tywalch/electrodb",
        description:
          "A library to ease the use of having multiple entities and complex hierarchical relationships.",
      },
      {
        title: "dynamodb-onetable",
        count: 184,
        link: "https://github.com/sensedeep/dynamodb-onetable",
        description:
          "DynamoDB OneTable (OneTable) is an access library for DynamoDB applications that use one-table design patterns with NodeJS.",
      },
      {
        title: "dynamodb-metrics",
        count: 10,
        link: "https://www.npmjs.com/package/dynamodb-metrics",
        description:
          "DynamoDB Metrics calculates detailed DynamoDB metrics for single table design patterns.",
      },
    ],
    typescript: [
      {
        title: "DynamoDB Toolbox",
        count: 812,
        link: "https://github.com/jeremydaly/dynamodb-toolbox",
        description:
          "Simple set of tools for working with Amazon DynamoDB and the DocumentClient",
      },
      {
        title: "DynamoDB DataMapper For JavaScript",
        count: 465,
        link: "https://github.com/awslabs/dynamodb-data-mapper-js",
        description: "A schema-based data mapper for Amazon DynamoDB",
      },
      {
        title: "DynamoTypes",
        count: 123,
        link: "https://github.com/balmbees/dynamo-types",
        description: "Typescript AWS DynamoDB ORM",
      },
      {
        title: "TypeDynamo",
        count: 41,
        link: "https://github.com/lucasmafra/type-dynamo",
        description:
          "Easy-to-use ORM for your Typescript projects with DynamoDB and Node.js",
      },
      {
        title: "Dynoexpr",
        count: 74,
        link: "https://github.com/tuplo/dynoexpr",
        description: "Expression builder for AWS.DynamoDB.DocumentClient",
      },
      {
        title: "dynamodb-pipeline",
        count: 10,
        link: "https://github.com/RossWilliams/dynamo-pipeline",
        description: "Alternative API for DynamoDB DocumentClient",
      },
      {
        title: "dynamodb-geo.js",
        count: 167,
        link: "https://github.com/rh389/dynamodb-geo.js",
        description:
          "A node-friendly typescript port of https://github.com/awslabs/dynamodb-geo",
      },
    ],
    python: [
      {
        title: "PynamoDB",
        count: "1.2k",
        link: "https://github.com/pynamodb/PynamoDB",
        description:
          "Pythonic interface for Amazon's DynamoDB that supports Python 2 and 3",
      },
      {
        title: "Flywheel",
        count: 120,
        link: "https://github.com/stevearc/flywheel",
        description:
          "Object mapper for Amazon's DynamoDB, not supported anymore",
      },
      {
        title: "DynamORM",
        count: 51,
        link: "https://github.com/NerdWalletOSS/dynamorm",
        description:
          "Library with a goal of Abstracting away the interaction with the underlying DynamoDB libraries",
      },
      {
        title: "django-dynamodb-sessions",
        count: 90,
        link: "https://github.com/gtaylor/django-dynamodb-sessions",
        description: "A Django session backend for Amazon's DynamoDB",
      },
      {
        title: "dynamic-dynamodb",
        count: 605,
        link: "https://github.com/sebdah/dynamic-dynamodb",
        description: "Dynamic DynamoDB provides auto scaling for AWS DynamoDB",
      },
      {
        title: "flask-dynamo",
        count: 135,
        link: "https://github.com/rdegges/flask-dynamo",
        description: "DynamoDB integration for Flask",
      },
      {
        title: "dynamodb-json",
        count: 114,
        link: "https://github.com/Alonreznik/dynamodb-json",
        description:
          "DynamoDB json util to load and dump strings of Dynamodb json format to python object and vise-versa",
      },
    ],
    golang: [
      {
        title: "Dynamo",
        count: 537,
        link: "https://github.com/guregu/dynamo",
        description: "Expressive DynamoDB library for Go",
      },
      {
        title: "Dynamolock",
        count: 62,
        link: "https://github.com/cirello-io/dynamolock",
        description: "DynamoDB Lock Client for Go",
      },
    ],
    ruby: [
      {
        title: "Dynamoid",
        count: 372,
        link: "https://github.com/Dynamoid/dynamoid",
        description: "Ruby ORM for Amazon's DynamoDB.",
      },
      {
        title: "fake_dynamo",
        count: 372,
        link: "https://github.com/ananthakumaran/fake_dynamo",
        description:
          "Local hosted, in-memory Amazon DynamoDB emulator for Ruby",
      },
    ],
    php: [
      {
        title: "laravel-dynamodb [★ 346]",
        link: "https://github.com/baopham/laravel-dynamodb",
        description: "Eloquent syntax for DynamoDB in PHP",
      },
    ],
    dotnet: [
      {
        title: "Dynamo.ORM [★ 11]",
        link: "https://github.com/marcodafonseca/Dynamo.ORM",
        description:
          "An async ORM built for Amazon Web Service's DynamoDb in .Net Standard",
      },
    ],
  },
}
